<template>
  <div class="downloadMyDataWithToken">
    <TokenStatus
        operation="CONFIRM_DOWNLOAD_MY_DATA"
        :token="downloadMyDataToken"
        token-name="downloadMyDataToken"
        @token-is-valid="() => tokenIsValid=true"
        @token-is-invalid="() => tokenIsValid=false"
        @token-has-expired="() => tokenIsValid=false"
    ></TokenStatus>
    <Transition>
      <form
          v-if="tokenIsValid"
          class="form"
      >
        <h1 class="heading2">Get a copy of my data
          <hr class="gradientLine">
        </h1>
        You will receive a copy of our data about you via email in a few minutes.
        <div class="flex items-center justify-between">
          <button
              class="button primary"
              type="button">
            Get a copy of my data
          </button>
        </div>
      </form>
    </Transition>
  </div>
</template>

<script>
import {ref, watch} from "vue";
import TokenStatus from "@/components/authentication/TokenStatus.vue";
import {useStatusMessage} from "@/composables/useStatusMessage";
import {CONFIRM_DOWNLOAD_MY_DATA} from "@/store/operations";

export default {
  name: "DownloadMyDataWithToken",
  components: {
    TokenStatus,
  },
  props: {
    downloadMyDataToken: {
      type: String,
      required: true
    },
  },
  setup() {
    const tokenIsValid = ref(null)
    const {setMessage, deleteMessage} = useStatusMessage(CONFIRM_DOWNLOAD_MY_DATA)

    deleteMessage()

    watch(tokenIsValid, value => {
      if (value === true) setMessage({isSuccess: true, body: "Your token is valid."})
      else if (value === false) setMessage({isError: true, body: 'Your token is invalid. It may have been used before or it may have expired. Please try again.'})
    })
    return {
      tokenIsValid,
    }
  }
}
</script>

<style scoped>

</style>